(function () {
  var that = this;

  this.BigSpinner = function () {};

  this.BigSpinner.start = function () {
    var me = this;

    this._element = $('#big_spinner');
    this._progressBar = $('.progress_bar', this._element).first();
    this._maxWidth = 244;

    that.BigSpinner.setWidth(0);
    $(this._element).show();

    $(document).waitForImages(
      function () {
        $(me._element).hide();
      },
      function (loaded, count, success) {
        that.BigSpinner.setWidth(Math.round((loaded / count) * me._maxWidth));
      },
      $('div').length < 10000
    );
  };

  this.BigSpinner.setWidth = function (width) {
    this._onBar = $('.on_bar', this._element).first();
    $(this._onBar).css('width', width + 'px');
  };
}).call(window.pmatrix.ui);
