/**
 * Intended usage:
 *
 * Case 1 (more configurable; all parameters are optional):
 *   pmatrix.ui.FlashPopup.create({
 *     message: 'my message',
 *     fade_time: <time before message fades out>,
 *     height: <height>,
 *     width: <width>,
 *     callback: function() {
 *       // called after fade out
 *     }
 *   });
 *
 * Case 2 (easier):
 *   pmatrix.ui.FlashPopup.create('my message');
 *
 * In both cases, the flash message displays immediately. Previously-displayed
 * popups are hidden and re-appear after the new one fades away.
 */

(function () {
  var that = this;

  /** @constructor */
  this.FlashPopup = function (options) {
    this._setOptions(options);
  };

  this.FlashPopup.prototype._setOptions = function (options) {
    // Defaults
    this._fadeTime = 3000;
    this._height = 170;
    this._width = 400;
    this._message = '';
    this._domID = 'flash_message_popup';
    this._theme = 'standard';
    this._callback = null;
    this._children = [];
    this._timer = null;

    if (typeof options.fade_time != 'undefined') {
      this._fadeTime = options.fade_time;
    }
    if (typeof options.height != 'undefined') {
      this._height = options.height;
    }
    if (typeof options.width != 'undefined') {
      this._width = options.width;
    }
    if (typeof options.message != 'undefined') {
      this._message = options.message;
    }
    if (typeof options.callback == 'function') {
      this._callback = options.callback;
    }
    if (typeof window.themeName != 'undefined') {
      this._theme = window.themeName;
    }
  };

  this.FlashPopup.prototype.stash = function () {
    if (this._timer != null) {
      clearTimeout(this._timer);
      this._timer = null;
    }
    this._remove();
  };

  this.FlashPopup.prototype.startTimer = function () {
    var me = this;
    this._timer = setTimeout(function () {
      $(me._popup).fadeOut('slow', function () {
        me.destroy();
      });
    }, this._fadeTime);
  };

  this.FlashPopup.prototype._remove = function () {
    $(this._popup).remove();
  };

  this.FlashPopup.prototype.destroy = function () {
    this._remove();
    if (typeof this._callback == 'function') {
      this._callback();
    }
    while (this._children.length) {
      var controller = this._children.pop();
      controller.show();
    }
  };

  this.FlashPopup.prototype.show = function () {
    var me = this;

    // Remove existing popup
    var controller = $('#' + this._domID).data('controller');
    if (typeof controller == 'object') {
      controller.stash();
      this._children.push(controller);
    }

    var z_index = getHighestZIndex() + 100;

    this._popup = $(
      '<div id="' +
        this._domID +
        '" class="popup_window dialog" style="z-index: ' +
        z_index +
        '">' +
        '<div class="back">' +
        '<div class="top"></div>' +
        '<div class="tile"></div>' +
        '<div class="bottom"></div>' +
        '</div>' +
        '<div class="outer">' +
        '<div class="tile">' +
        '<img src="/assets/slice_assets/' +
        this._theme +
        '/shared/light_box/inner_window_gradient.png" class="bg">' +
        '<div class="buffer">' +
        '<div class="wrapper" style="z-index: ' +
        (z_index + 1) +
        '; text-align: center;">' +
        '<h3 class="alert_content"></h3>' +
        '</div>' +
        '</div>' +
        '</div>' +
        '</div>' +
        '</div>'
    );

    $(this._popup)
      .appendTo('body')
      .click(function () {
        me.destroy();
      })
      .data('controller', this);

    var content = $('#' + this._domID + ' .alert_content').first();
    $(content).html(this._message);

    $(this._popup)
      .height(this._height)
      .width(this._width)
      .offset({
        left: Math.max(($(window).width() - $(this._popup).width()) / 2, -100),
        top: Math.max(
          (Math.min($(window).height(), 1000) - $(this._popup).height()) / 2 +
            $(document).scrollTop(),
          $(document).scrollTop() - 100
        ),
      });

    var padding = 70;
    if ($(content).height() > $(this._popup).height() - 2 * padding) {
      this._height = $(content).height() + 2 * padding;
      var l = Math.max(($(window).width() - $(this._popup).width()) / 2, -100);
      var t = Math.max(
        (Math.min($(window).height(), 1000) - $(this._popup).height()) / 2 +
          $(document).scrollTop(),
        $(document).scrollTop() - 100
      );
      $(this._popup)
        .css('height', this._height + 'px')
        .css('width', this._width + 'px')
        .css('left', l + 'px')
        .css('top', t + 'px');
    }

    /*
    var mt = ( $(content).parent().height() - $(content).height() ) / 2;
    $(content)
      .css('padding-top', '0');
      .css('margin-top', mt + 'px');
      */

    this.startTimer();
  };

  this.FlashPopup.create = function (options) {
    // Handle optional use case where only the message is passed as the argument with no options
    if (typeof options == 'string') {
      options = {
        message: options,
      };
    }
    var p = new that.FlashPopup(options);
    p.show();
  };
}).call(window.pmatrix.ui);

if (!window.browser_alert) {
  window.browser_alert = window.alert;
}
window.alert = function (message1, message2) {
  if (message2 == undefined) {
    setTimeout(function () {
      swal(message1);
    }, 300);
  } else {
    setTimeout(function () {
      swal(message1, message2);
    }, 300);
  }
};

window.browser_confirm = window.confirm;
window.confirm = function (message, callback, options) {
  var options = options || {};
  if (typeof callback !== 'function') {
    return browser_confirm(message);
  } else {
    setTimeout(function () {
      swal({
        icon: 'warning',
        text: message,
        title: options['title'] || '',
        buttons: {
          cancel: {
            visible:
              typeof options['showCancelButton'] === 'undefined'
                ? true
                : options['showCancelButton'],
            text: options['cancelButtonText'] || 'No',
            closeModal:
              typeof options['closeOnCancel'] === 'undefined'
                ? true
                : options['closeOnCancel'],
            value: false,
          },
          confirm: {
            text: options['confirmButtonText'] || 'Yes',
            closeModal:
              typeof options['closeOnConfirm'] === 'undefined'
                ? true
                : options['closeOnConfirm'],
            value: true,
            className: 'confirm-color',
            // colors need to be set thru CSS, the color code ("#DD6B55") is put in sweetalert.css
          },
        },
      }).then(function (isConfirm) {
        callback(isConfirm);
      });
    }, 300);
  }
};

window.browser_prompt = window.prompt;
window.prompt = function (message, callback, options) {
  var options = options || {};
  if (typeof callback !== 'function')
    throw 'The callback given to confirm must be a function.';
  else {
    setTimeout(function () {
      swal({
        icon: options['icon'] || 'warning',
        content: {
          element: 'input',
        },
        text: message,
        title: options['title'] || '',
        buttons: {
          cancel: {
            visible:
              typeof options['showCancelButton'] === 'undefined'
                ? true
                : options['showCancelButton'],
            text: options['cancelButtonText'] || 'Cancel',
            closeModal:
              typeof options['closeOnCancel'] === 'undefined'
                ? true
                : options['closeOnCancel'],
            value: false,
          },
          confirm: {
            text: options['confirmButtonText'] || 'Enter',
            closeModal:
              typeof options['closeOnConfirm'] === 'undefined'
                ? true
                : options['closeOnConfirm'],
            value: true,
            className: 'prompt-color',
            // colors need to be set thru CSS, the color code (#00A8FF) is put in sweetalert.css
          },
        },
      }).then(function (isConfirm) {
        if (isConfirm) callback(isConfirm);
      });
    }, 300);
  }
};
